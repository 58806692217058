import React from "react";
import NavBar from "../../components/navigation-header";
import "../../styling/templates.css";
import Footer from "../../components/footer";
import ContactForm from "./contact-us-form";
//import HomeImage from "../../images/sri-lanka-lake-sri-lanka-landscape-trees-water-trees-lake-1.avif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export default function contactUs() {
  return (
    <div>
      <NavBar />

      <div style={{ position: "relative" }}>
        <img
          className="home-image"
          alt=""
          src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/contact-us/sri-lanka-lake-sri-lanka-landscape-trees-water-trees-lake-1-94e2b169c3371b65827aaaa3615f8025.avif"
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "80vh",
            backgroundColor: "rgba(0, 0, 0, 0.24)",
            zIndex: 0,
          }}
        ></div>
        <div className="video-text-box">
          <div className="video-background-text">
            <h1 className="video-topic">CONTACT US</h1>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "100px" }}>
        <center>
          <div className="page-content">
            <div className="container-3-column">
              <div className="column-3">
                <h3 className="contact-icon-topic">Head Office</h3>
                <div className="footer-horizontal"></div>
                <table
                  style={{
                    width: "100%",
                    marginTop: "20px",
                    textAlign: "start",
                  }}
                >
                  {/* Column 2 Content - 1 */}
                  <tr>
                    <td style={{ width: "30px", verticalAlign: "top" }}>
                      <div className="contact-text-icon">
                        <a
                          href="https://www.google.com/maps/dir/6.9271552,79.8621696/Eco+Spindles+head+office/@6.9234847,79.8570101,16z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3ae25967ac881241:0x5bfe7c134ff43796!2m2!1d79.8574137!2d6.9201024"
                          aria-label="icon"
                        >
                          <FontAwesomeIcon icon={faLocationDot} />
                        </a>
                      </div>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <h4 className="contact-icon-text-t">
                        <a
                          href="https://www.google.com/maps/dir/6.9271552,79.8621696/Eco+Spindles+head+office/@6.9234847,79.8570101,16z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3ae25967ac881241:0x5bfe7c134ff43796!2m2!1d79.8574137!2d6.9201024"
                          aria-label="icon"
                        >
                          Eco Spindles (Private) Limited
                        </a>
                      </h4>
                      <p className="contact-icon-text">
                        No. 278/4, Level 17, Access Towers,
                        <br />
                        Union Place, Colombo 2, Sri Lanka.
                      </p>
                    </td>
                  </tr>

                  {/* Column 2 Content - 2 */}
                  <tr>
                    <td style={{ width: "30px", verticalAlign: "top" }}>
                      <div className="contact-text-icon">
                        <a href="tel:+94112307168" aria-label="icon">
                          <FontAwesomeIcon icon={faPhone} rotation={270} />
                        </a>
                      </div>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <h4 className="contact-icon-text-t">
                        <a href="tel:+94112307168" aria-label="phone">
                          Phone
                        </a>
                      </h4>
                      <p className="contact-icon-text">+94 11 2307168</p>
                    </td>
                  </tr>

                  {/* Column 2 Content - 3 */}
                  <tr>
                    <td style={{ width: "30px", verticalAlign: "top" }}>
                      <div className="contact-text-icon">
                        <a href="tel:+94112307170" aria-label="phone">
                          <FontAwesomeIcon icon={faPhone} rotation={270} />
                        </a>
                      </div>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <h4 className="contact-icon-text-t">
                        <a href="tel:+94112307170" aria-label="phone">
                          Phone
                        </a>
                      </h4>
                      <p className="contact-icon-text" aria-label="phone">
                        +94 11 2307170
                      </p>
                    </td>
                  </tr>

                  {/* Column 2 Content - 4 */}
                  <tr>
                    <td style={{ width: "30px", verticalAlign: "top" }}>
                      <div className="contact-text-icon">
                        <a
                          href="mailto:info@ecospindles.com"
                          aria-label="email"
                        >
                          <FontAwesomeIcon icon={faEnvelope} />
                        </a>
                      </div>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <h4 className="contact-icon-text-t">Email</h4>
                      <p className="contact-icon-text-email">
                        More Info -{" "}
                        <a
                          href="mailto:info@ecospindles.com"
                          aria-label="email"
                          className="email-2"
                        >
                          info@ecospindles.com
                        </a>
                        <br />
                        Careers -{" "}
                        <a
                          href="mailto:careers@ecospindles.com"
                          aria-label="email"
                          className="email-2"
                        >
                          careers@ecospindles.com
                        </a>
                      </p>
                    </td>
                  </tr>
                </table>
              </div>

              <div className="column-3">
                <h3 className="contact-icon-topic">Factories</h3>
                <div className="footer-horizontal"></div>
                <table
                  style={{
                    width: "100%",
                    marginTop: "20px",
                    textAlign: "start",
                  }}
                >
                  {/* Column 3 Content - 1 */}
                  <tr>
                    <td style={{ width: "30px", verticalAlign: "top" }}>
                      <div className="contact-text-icon">
                        <a
                          href="https://www.google.com/maps/dir/6.9271552,79.8621696/ECO+sPINDLES+MAUGAMA/@6.8830283,79.8100748,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3ae24c6f1857017b:0xe07aa1a40584e958!2m2!1d80.0399572!2d6.747481"
                          aria-label="icon"
                        >
                          <FontAwesomeIcon icon={faLocationDot} />
                        </a>
                      </div>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <h4 className="contact-icon-text-t">
                        <a
                          href="https://www.google.com/maps/dir/6.9271552,79.8621696/ECO+sPINDLES+MAUGAMA/@6.8830283,79.8100748,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3ae24c6f1857017b:0xe07aa1a40584e958!2m2!1d80.0399572!2d6.747481"
                          aria-label="icon"
                        >
                          Synthetic Filament Factory
                        </a>
                      </h4>
                      <p className="contact-icon-text">
                        Batuvita, Mawgama,
                        <br />
                        Horana, Sri Lanka.
                      </p>
                    </td>
                  </tr>

                  {/* Column 3 Content - 2 */}
                  <tr>
                    <td style={{ width: "30px", verticalAlign: "top" }}>
                      <div className="contact-text-icon">
                        <a href="tel:+94342265489" aria-label="icon">
                          <FontAwesomeIcon icon={faPhone} rotation={270} />
                        </a>
                      </div>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <h4 className="contact-icon-text-t">
                        <a href="tel:+94342265489">Phone</a>
                      </h4>
                      <p className="contact-icon-text-t">+94 342 265 489</p>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="column-3">
                <h3 className="contact-icon-topic">&nbsp;</h3>
                <div className="footer-horizontal-2"></div>
                <table
                  style={{
                    width: "100%",
                    marginTop: "20px",
                    textAlign: "start",
                  }}
                >
                  {/* Column 3 Content - 1 */}
                  <tr>
                    <td style={{ width: "30px", verticalAlign: "top" }}>
                      <div className="contact-text-icon">
                        <a
                          href="https://www.google.com/maps/dir/6.9271552,79.8621696/boi+zone+horana+poruwadanda/@6.8605959,79.8512165,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3ae3b37c10d4ff45:0x5eb1a06eb51ba400!2m2!1d80.1365539!2d6.7392774"
                          aria-label="icon"
                        >
                          <FontAwesomeIcon icon={faLocationDot} />
                        </a>
                      </div>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <h4 className="contact-icon-text-t">
                        <a
                          href="https://www.google.com/maps/dir/6.9271552,79.8621696/boi+zone+horana+poruwadanda/@6.8605959,79.8512165,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3ae3b37c10d4ff45:0x5eb1a06eb51ba400!2m2!1d80.1365539!2d6.7392774"
                          aria-label="icon"
                        >
                          Recycling And Yarn Factory
                        </a>
                      </h4>
                      <p className="contact-icon-text">
                        Lot - 7, Horana EPZ, Boralugoda,
                        <br />
                        Poruwadanda, Sri Lanka.
                      </p>
                    </td>
                  </tr>

                  {/* Column 3 Content - 2 */}
                  <tr>
                    <td style={{ width: "30px", verticalAlign: "top" }}>
                      <div className="contact-text-icon">
                        <a href="tel:+94342255874" aria-label="icon">
                          <FontAwesomeIcon icon={faPhone} rotation={270} />
                        </a>
                      </div>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <h4 className="contact-icon-text-t">
                        <a href="tel:+94342255874">Phone</a>
                      </h4>
                      <p className="contact-icon-text">+94 342 255 874</p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </center>
      </div>

      <div style={{ marginTop: "100px", marginBottom: "-10px" }}>
        <center>
          <div className="page-content-3">
            <div className="two-column-para">
              <div className="column-para column1-para">
                {/* Column 1 */}
                <div>
                  <ContactForm />
                </div>
              </div>
              <div className="column-para column2-para">
                {/* Column 2 */}
                <div>
                  <iframe
                    title="Google Maps Location"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d506973.55916050076!2d79.852919!3d6.922734!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25915901b1397%3A0x41af85942534566a!2s17%20Union%20Pl%2C%20Colombo%2C%20Sri%20Lanka!5e0!3m2!1sen!2sus!4v1703138518294!5m2!1sen!2sus"
                    width="100%"
                    height="930"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </center>
      </div>

      <Footer />
    </div>
  );
}
