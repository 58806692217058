import React, { useState } from "react";
import "./contact-us-form.css";
import { useForm } from "react-hook-form";
import HCaptcha from "@hcaptcha/react-hcaptcha";

function EnquiryForm() {
  const { setValue } = useForm();

  const onHCaptchaChange = (token) => {
    setValue("h-captcha-response", token);
    setIsCaptchaVerified(true); // Update captcha verification state
  };

  // Declare state variables using useState
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false); // New state for captcha verification

  // Custom email validation function
  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // Standard email pattern
    return emailPattern.test(email);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const email = event.target.email.value;

    // Check if email is valid
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      setSuccessMessage("");
      return; // Stop form submission if email is invalid
    } else {
      setEmailError(""); // Clear any previous email error
    }

    // Check if captcha is verified
    if (!isCaptchaVerified) {
      setErrorMessage("Please complete the hCaptcha verification.");
      setSuccessMessage("");
      return; // Stop form submission if captcha is not verified
    }

    try {
      const formData = new FormData(event.target);

      formData.append("subject", "ECO SPINDLES - CONTACT US");
      formData.append("access_key", "3b5055db-a06b-4b8d-9ce0-2e62dd32aa39");

      const object = Object.fromEntries(formData);
      const json = JSON.stringify(object);

      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      });

      if (res.ok) {
        const response = await res.json();
        if (response.success) {
          setSuccessMessage("Form submitted successfully!");
          setErrorMessage("");
          window.location.href = formData.get("redirect");
        } else {
          setErrorMessage("Form submission failed. Please try again.");
          setSuccessMessage("");
        }
      } else {
        setErrorMessage("Form submission failed. Please try again.");
        setSuccessMessage("");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setErrorMessage("An error occurred. Please try again later.");
      setSuccessMessage("");
    }
  };

  return (
    <div className="contact-us-con">
      <form onSubmit={onSubmit} id="ecospindlescontactus">
        <div>
          <h2 className="f-main-topic">Contact Us</h2>
        </div>
        <div
          style={{
            padding: "30px",
          }}
        >
          <p className="contact-form">Name:</p>
          <div className="container-contact">
            <div className="column-contact first-contact">
              <input
                type="text"
                name="firstname:"
                required
                className="inputStyle"
              />
              <p className="contact-form-sub">First Name</p>
              <span className="contacts-screen-pc">
                {" "}
                <br />
              </span>
            </div>
            <div className="column-contact second-contact">
              <input
                type="text"
                name="lastname:"
                required
                className="inputStyle"
              />
              <p className="contact-form-sub">Last Name</p>
              <br />
            </div>
          </div>
          <p className="contact-form ft-top">Email:</p>
          <input type="email" name="email" required className="inputStyle" />
          <p className="contact-form-sub">example@example.com</p>
          {emailError && <div className="error-message">{emailError}</div>}
          <br />
          <p className="contact-form ft-top">Message:</p>
          <textarea
            name="message"
            rows="5"
            required
            className="textareaStyle"
            placeholder="Type here..."
          ></textarea>
          <br />
          <br />
          <p className="contact-form">
            Please verify that you are human <span className="red-star">*</span>
          </p>
          <HCaptcha
            sitekey="50b2fe65-b00b-4b9e-ad62-3ba471098be2"
            reCaptchaCompat={false}
            onVerify={onHCaptchaChange}
          />
          {/* <input
            type="hidden"
            name="ccemail"
            value="shenali@3cs.solutions; priyankara@3cs.solutions"
          /> */}
          <input
            type="hidden"
            name="ccemail"
            value="info@ecospindles.com; info@3cs.lk"
          />
          <input type="checkbox" name="botcheck" style={{ display: "none" }} />
          <input name="from_name" type="hidden" value="ECO Spindles" />
          <input
            name="subject"
            type="hidden"
            value="ECO SPINDLES - CONTACT US"
          />
          <input
            type="hidden"
            name="redirect"
            value="https://web3forms.com/success"
          />
        </div>
        <div className="f-main-bottom">
          <div className="form-footer">
            <center>
              <button
                type="submit"
                className="btn submitButtonContact btn-lg"
                id="button"
              >
                Submit
              </button>
            </center>
            <center>
              {successMessage && (
                <div className="success-message">{successMessage}</div>
              )}
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
            </center>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EnquiryForm;
